import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import all the components for which navigation service has to be activated 
import { SignInComponent } from './components/sign-in/sign-in.component';
import { MainFormComponent } from './components/main-form/main-form.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { HelpComponent } from './components/help/help.component';
import { AppComponent } from './app.component';
import { MsalGuard } from '@azure/msal-angular';
import { LicenseComponent } from './license/license.component';

const isIframe = window !== window.parent && !window.opener;

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full', canActivate: [ MsalGuard ]},
  { path: 'help', component: HelpComponent},
  { path: 'query', component: MainFormComponent, canActivate: [MsalGuard]},
  { path: 'sign-in', component: SignInComponent },
  { path: 'license', component: LicenseComponent}
  ]

  @NgModule({
    imports: [RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
    })],
    exports: [RouterModule]
  })
export class AppRoutingModule { }
