import {Component, OnInit, ElementRef, ViewChild, Input} from '@angular/core';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

@Component({
  selector: 'app-nlp',
  styleUrls: ['./nlp.component.scss'],
  template: `<div class='wordcloud-chart'> <iframe id="ifrm" #ifrm [src]="urlSafe" width="100%" height="650px" frameBorder="0" (load)="onIframeLoad()" style="/*display:none;*/"> <p> Your browser does not support iframes</p> </iframe> </div>`
})
export class NlpComponent implements OnInit {
  @ViewChild('ifrm', {static: false}) iframe: ElementRef;
  @Input()
  url: string = "./assets/visualisations/keyphrasecloud.html";
  urlSafe: SafeResourceUrl;
  @Input()
  data: any;
  private isInited: boolean;
  receiveMessage: EventListener;

  constructor(public sanitizer: DomSanitizer) { 
  	this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  ngOnInit() {
  	//console.log(this.data);
  }

  ngOnChanges(): void {
    this.iframe.nativeElement.contentWindow.postMessage(this.data, '*');
  }

  ngAfterViewInit() {
        this.isInited = true;
      }

   onIframeLoad() {
    if (this.isInited) {
      setTimeout(() => {
      	// send data to iframe
        this.iframe.nativeElement.contentWindow.postMessage(this.data, '*');
        window.addEventListener('message', this.receiveMessage, false);
      }, 500);
    }
  }

}
