export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCBifHthxWVj64vBlR6e-NOWwqv4DiQI8A",
    authDomain: "thenewloom.firebaseapp.com",
    projectId: "thenewloom",
    storageBucket: "thenewloom.appspot.com",
    messagingSenderId: "757610510511",
    appId: "1:757610510511:web:500cebd968efdf1c99bc5a",
    measurementId: "G-R9B4PE116Q"
  }
};
