import { Component, OnInit, OnChanges, ViewChild, AfterViewInit, Renderer2, ElementRef, EventEmitter, Output} from '@angular/core';
import { MainFormComponent } from './components/main-form/main-form.component';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  @ViewChild('title', {static: false}) animatedTitle: ElementRef<HTMLCanvasElement>;
  @Output() sidenavClose = new EventEmitter();

  title: string = 'The Loom';
  native;
  currentPath: any;

  constructor(private renderer: Renderer2, private router: Router, private authService: MsalService, private msalBroadcastService: MsalBroadcastService) {
      router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentPath = this.router.url;
      console.log(this.currentPath)
    }); 
  }

  ngOnInit(): void {
      this.authService.handleRedirectObservable().subscribe({
        next: (result) => { console.log(result) },
                            // this.router.navigate(['/query'])},
        error: (error) => console.log('ERROR', error)
      }); }

  ngAfterViewInit() {
   }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

 }