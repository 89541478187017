import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainFormComponent } from './components/main-form/main-form.component';
import { FilterPipe } from './components/main-form/filter.pipe';
import { DialogElementsExampleDialog } from './components/main-form/main-form.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthService } from "./shared/services/auth.service";
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { HelpComponent } from './components/help/help.component';
import { LicenseComponent } from './license/license.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { NlpComponent } from './components/nlp-wordcloud/nlp.component';
import { NlpThematicComponent } from './components/nlp-thematic/nlp-thematic.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';

import {MatToolbarModule} from '@angular/material/toolbar'; 
import {MatIconModule} from '@angular/material/icon';;
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule, MatSidenavModule, MatListModule} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule, MatNativeDateModule} from '@angular/material';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRippleModule} from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';

import {ScrollingModule} from '@angular/cdk/scrolling';
import { environment } from '../environments/environment';
import { NavbarComponent } from './navbar/navbar.component';
import { DashComponent } from './dash/dash.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from '@angular/cdk/layout';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    MainFormComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    HelpComponent,
    LicenseComponent,
    BarChartComponent,
    NlpComponent,
    DialogElementsExampleDialog,
    NlpThematicComponent,
    NavbarComponent,
    FilterPipe,
    DashComponent
  ],
  entryComponents: [DialogElementsExampleDialog],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule, 
    MatSelectModule,
    MatCheckboxModule, 
    MatCardModule,
    MatChipsModule,
    FormsModule, 
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatButtonModule,
    HttpClientModule,
    MatSnackBarModule, 
    NgxMatSelectSearchModule,
    ScrollingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRippleModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatGridListModule,
    LayoutModule,
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
          clientId: 'ede48daf-af59-41d9-ab14-44e4495b176b',
          authority: "https://login.microsoftonline.com/2b755fa1-23d1-48f3-98fc-6fdc1dc48d69",
          redirectUri: "https://new.theloom.io/query"
      },
      cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        }
    }), {
      interactionType: InteractionType.Redirect, // Msal Guard Configuration
      authRequest: {
          scopes: ['user.read']
      }
    }, null)
    // MsalModule.forRoot( news PublicClientApplication({
    //   auth: {
    //     clientId: 'ede48daf-af59-41d9-ab14-44e4495b176b', // This is your client ID
    //     authority: "https://login.microsoftonline.com/2b755fa1-23d1-48f3-98fc-6fdc1dc48d69", // This is your tenant ID
    //     redirectUri: "http://localhost:4200/query",// 'https://new.theloom.io/query', // 'http://localhost:4200/query' // https://new.theloom.io/query  // This is your redirect URI
    //     navigateToLoginRequestUrl: false
    //   },
    //   cache: {
    //     cacheLocation: 'localStorage',
    //     storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    //   }
    // }), null, null)
  ], 
  exports: [MatMenuModule],
  providers: [AuthService, {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }
