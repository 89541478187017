import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  AfterViewChecked,
  Input,
  Directive,
  ElementRef,
  Pipe,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Inject,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { BehaviorSubject, of } from "rxjs";
import { debounceTime, filter, tap } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MatCheckboxChange,
  MatSelect,
  MatTab,
  MatOption,
  MatInput,
} from "@angular/material";
import { MatSelectSearchVersion } from "ngx-mat-select-search";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  CdkVirtualScrollViewport,
  ScrollDispatcher,
} from "@angular/cdk/scrolling";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { formatDate } from "@angular/common";
import { Observable } from "rxjs";
import { DataModel } from "../../data/data.model";
import * as d3 from "d3";
import { AuthService } from "../../shared/services/auth.service";
import { SavedQueriesService } from "../../shared/services/saved-queries.service";
import requestTemplate from "../../../assets/linkedin_request_template.json";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { BlobServiceClient } from '@azure/storage-blob';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

export interface IRequestOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  observe: "response";
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: "json";
  withCredentials?: boolean;
}

// link dialog html
@Component({
  selector: "dialog-elements-example-dialog",
  templateUrl: "progress-dialog.html",
})

export class DialogElementsExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogElementsExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}

export class SafeStylePipe {
  constructor(private sanitizer: DomSanitizer) {}
  transform(val: string) {
    return this.sanitizer.bypassSecurityTrustStyle(val);
  }
}

export interface DialogData {
  scraping_progress: "In progress..." | "Completed" | "Failed";
  cloud_progress: "In progress..." | "Pending" | "Completed" | "Failed";
  thematic_progress: "In progress..." | "Pending" | "Completed" | "Failed";
}

@Pipe({
  name: "safeStyle",
})
@Component({
  selector: "app-main-form",
  templateUrl: "./main-form.component.html",
  styleUrls: ["./main-form.component.scss"],
})
export class MainFormComponent implements OnInit {
  @ViewChild("matTarget", { static: false }) matTarget: MatSelect;
  @ViewChild("matInclusionFilter", { static: false })
  matInclusionFilter: MatSelect;
  @ViewChild("matExclusionFilter", { static: false })
  matExclusionFilter: MatSelect;
  @ViewChild("matExclusionGroup", { static: false })
  matExclusionGroup: MatSelect;
  @ViewChild("matInclusionGroup", { static: false })
  matInclusionGroup: MatSelect;
  @ViewChild("fromInput", { static: false }) fromInput: MatInput;
  @ViewChild("isScrapedCheck", { static: false }) isScrapedCheck: ElementRef;
  private dialogRef: MatDialogRef<DialogElementsExampleDialog>;

  _inclusionPretty: string = "";
  _exclusionPretty: string = "";
  _targetsPretty: string = "";

  public get htmlProperty(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this._inclusionPretty);
  }


  matSelectSearchVersion = MatSelectSearchVersion;
  targets: string[] = [];
  filterGroups: string[] = [];
  urnMapping: object = {};
  isLoading: boolean = false;
  isTabDisabled = true;
  isNLPTabDisabled = true;
  isAdmin: boolean = false;
  adminList: string[] = ['juan.delgado3', 'peter.simkin', 'magda.zubala'];
  showDownload: boolean = false;
  matchedURNs: object = {};
  isTaxonomySearch: boolean = false;
  isNLP: boolean = false;
  selectedRequest: string;
  isWordCloud: boolean = false;
  contentAnalysis: boolean = false;
  isContentAnalysisLoading: boolean = false;
  contentAnalysisProgress = {
    scraping_progress: "In progress...",
    cloud_progress: "Pending",
    thematic_progress: "Pending",
  };
  nlpToggle: string = "Thematic";
  isChecked: boolean = true;
  showCloud: boolean = false;
  showThematic: boolean = false;
  isJN: boolean = false;
  requestID: string;
  previousRequestIDs: string[] = [];
  recentQueryInfo: object = {};
  recentQueryTopics: object = {};
  downloadJsonHref: any;
  downloadJsonHrefScrape: any;
  firebase: any;
  gapi: any;
  data: any;
  selected: any = [];
  userEmail: string;
  uid: string;
  jobNo: string = "";
  mode: ProgressSpinnerMode = "indeterminate";
  start_date: string = "";
  end_date: string = "";
  maxDate = new Date(); // today
  minDate = new Date(); // placeholder
  dateOffset = 24 * 60 * 60 * 1000 * 30; //30 days
  format = "dd/MM/yyyy";
  locale = "en-GB";
  serializedStartDate;
  serializedEndDate;
  resultsData: Observable<DataModel>;
  apiData: Observable<DataModel>;
  wordcloudData: Observable<any>;
  thematicData: Observable<any>;
  isIframe = false;
  loginDisplay = false;
  // filter params
  filterForm = FormGroup;
  formInclusions = this.formBuilder.group({});
  formExclusions = this.formBuilder.group({});
  dateForm = this.formBuilder.group({
    StartDate: [this.minDate, Validators.required],
  });
  formFields: string[] = []; // Array to keep track of form field keys
  formFieldsExclusions = [];
  formInclusionURNs = [];
  lookupVal: string;
  metricInit: string[] = [
    "value",
    "engagementPercentage",
    "impressionPercentage",
    "engagementRate",
  ];
  filterName: string = "filters_map";
  // post params
  endpoint: string = "https://thenewloom.azure-api.net/thenewloom-fcns/li-api"; // "http://localhost:7071/api/li-api"; //"https://europe-west2-thenewloom.cloudfunctions.net/li_api-1";  //"http://localhost:5000/li_api-1" // linkedin api
  endpointScrape: string = "https://thenewloom.azure-api.net/thenewloom-fcns/scrape-fcn";  //"http://localhost:7071/api/scrape-fcn"; // returns urls then we carry out a scraping function
  endpointCloud: string = "https://thenewloom.azure-api.net/thenewloom-fcns/keyphrase-fcn"; // "http://localhost:7071/api/keyphrase-fcn"; // unsupervised key phrase extraction - picks out the most important e.g.
  endpointThematic: string = 'https://thenewloom.azure-api.net/thenewloom-fcns/thematic-fcn' // "http://localhost:7071/api/thematic-fcn"; //
  endpointTaxonomy: string =  "https://thenewloom.azure-api.net/thenewloom-fcns/search_taxonomies"; // "http://localhost:7071/api/search_taxonomies"; // taxonomy search cloud function
  endpointRecentQueries: string = "https://thenewloom.azure-api.net/thenewloom-fcns/recent_queries"; 
  endpointpopulateFilters: string = "https://thenewloom.azure-api.net/thenewloom-fcns/populate_filters";
  // target keys
  targetFacet: string = "filters_map";
  // filter keys
  filterFacets: object = {};
  // expected key values for final JSON
  magdaMap: object = {
    "industries_lookup.json": "industry",
    "industry_groups_lookup.json": "industry_group",
    "degrees_lookup.json": "degree",
    "titles_lookup.json": "job_title",
    "topics_lookup.json": "topic",
    "countries_lookup.json": "country",
    "functions_lookup.json": "function",
    "regions_lookup.json": "region",
    "country_groups_lookup.json": "country_group",
    "skills_lookup.json": "skill",
    "company_size_lookup.json": "company_size",
    "seniorities_lookup.json": "seniority",
    "organizations_lookup.json": "current_company",
    "gender_lookup.json": "gender",
    "domains_lookup.json": "domain",
    "None": "url",
    "education_status.json": "education_status",
    "fields_of_study_lookup.json": "field_of_study",
    "age_range_lookup.json": "age_range",
    "states_lookup.json": "state",
  };
  // initialise object to save filters
  savedFilters: object = {};
  savedExclusions: object = {};
  savedTargets: string[] = [];
  inclusionJSON: string;
  exclusionJSON: string;

  constructor(
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    readonly sd: ScrollDispatcher,
    private userAuth: AuthService,
    private savedQueries: SavedQueriesService,
    public dialog: MatDialog,
    private authService: MsalService, private msalBroadcastService: MsalBroadcastService
  ) {
    // set global variable to avoid having to use firebase ng module
    this.firebase = window["fireGlob"];
    this.gapi = window["gapiGlob"];
    // restrict date range & initialise start/ends
    this.minDate.setTime(this.minDate.getTime() - this.dateOffset);
    this.serializedStartDate = new FormControl(this.minDate.toISOString());
    this.serializedEndDate = new FormControl(this.maxDate.toISOString());
    this.start_date =
      formatDate(this.minDate, this.format, this.locale) + " 00:00:00";
    this.end_date =
      formatDate(this.maxDate, this.format, this.locale) + " 00:00:00";

  }

  disableSelect = new FormControl(false);

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      horizontalPosition: "right",
      panelClass: "notif",
    });
  }

  openDialog(scraping_progress, cloud_progress, thematic_progress) {
    this.dialog.open(DialogElementsExampleDialog, {
      data: {
        scraping_progress: scraping_progress,
        cloud_progress: cloud_progress,
        thematic_progress: thematic_progress,
      },
    });
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    // run filter pipeline
    this.populateFilters();
    // set user email + uid
    this.userAuth.afAuth.authState.subscribe((user) => {
      // this.uid = user["uid"];
      // this.userEmail = user["Rb"]["email"];
      // if (this.adminList.includes(this.userEmail.split('@')[0])) {
      //   this.isAdmin = true;
      // }
      // get time stamps of previous requests
      this.getRecentQueries(this.uid, null, 'timeStamp');
    });


  }

  getPreviousRequests() {
    const self = this;
    // get timestamps etc
    const savedLI = this.savedQueries.getTimeStamps(this.uid);
    // get time stamps of previous requests
    this.getRecentQueries(this.uid, null, 'timeStamp');
    this.getRecentQueries(this.uid, null, null);
  }

  postQueryPromise(data) {
    data = JSON.stringify(data);
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "74f3955f67e2489ca82801f19a6f89ac"
      }),
      responseType: "text",
    };

    // return request as promise
    return this.http.post<any>(this.endpoint, data, HTTPOptions).toPromise();
  }

  onToggleChange(event) {
    if (event) {
      this.showThematic = true;
      this.showCloud = false;
      this.nlpToggle = "Thematic";
    } else {
      this.showThematic = false;
      this.showCloud = true;
      this.nlpToggle = "Keyphrase Cloud";
    }
  }

  triggerScrape(data) {
    data = JSON.stringify(data);

    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "74f3955f67e2489ca82801f19a6f89ac",
      }),
      responseType: "text",
    };

    const headerDict = {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Headers": "*",
      "Access-Controinsl-Allow-Origin": "*",
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
      responseType: "text",
    };

    // return request as promise
    return this.http
      .post<any>(this.endpointScrape, data, HTTPOptions)
      .toPromise();
  }

  simpleAlert() {
    Swal.fire("Please enter a valid job number");
  }

  makeCloud(request_id) {
    this.contentAnalysisProgress.cloud_progress = "In progress...";
    request_id = JSON.stringify(request_id);

    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "74f3955f67e2489ca82801f19a6f89ac"
      }),
      responseType: "text",
    };

    this.http
      .post<any>(this.endpointCloud, request_id, HTTPOptions)
      .subscribe((data) => {
        this.wordcloudData = of(data);
        this.isNLP = true;
        this.isContentAnalysisLoading = false;
        this.isWordCloud = true;
        this.contentAnalysisProgress.cloud_progress = "Completed";
        this.openSnackBar("Wordcloud complete!", "Dismiss");
      });
  }

  makeThematic(request_id) {
    this.contentAnalysisProgress.thematic_progress = "In progress...";
    request_id = JSON.stringify(request_id);
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "74f3955f67e2489ca82801f19a6f89ac"
      }),
      responseType: "text",
    };

    const headerDict = {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
      responseType: "text",
    };

    this.http
      .post<any>(this.endpointThematic, request_id, HTTPOptions)
      .subscribe((data) => {
        var nlpURI = this.sanitizer.bypassSecurityTrustUrl(
          "data:text/csv;charset=utf-8," + encodeURIComponent(data)
        )
        this.downloadJsonHrefScrape = nlpURI;
        this.contentAnalysisProgress.thematic_progress = "Completed";
        console.log(data);
        this.thematicData = of(data);
        this.isNLP = true;
        this.isNLPTabDisabled = false;
        this.showThematic = true;
        this.showCloud = false;
      });
  }

  getRecentQueries(user_id, request_id, type) {
    console.log('Recent queries fetching...')
    var request = {"uid": user_id, "request_id": request_id, "type": type}
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "74f3955f67e2489ca82801f19a6f89ac"
      }),
      responseType: "text",
    };

     this.http
      .post<any>(this.endpointRecentQueries, request, HTTPOptions)
      .subscribe((data) => { 
        var requestJSON = JSON.parse(data);
        if (!type) {
          this.previousRequestIDs = requestJSON.map(function(d) { return d.request_id })
        } else if (type == 'timeStamp') {
          var datesOfQuery = requestJSON.map(function(d) { return d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date_created.split('.')[0]) })
          var queryIDS = requestJSON.map(function(d) { return d.query_id })
          // create lookup between query id & datetime
          this.recentQueryInfo = queryIDS.reduce((o, k, i) => ({...o, [k]: datesOfQuery[i]}), {});
        }
         })
    }

  callNLPPrevious(request_id) {
    console.log('Recent NLP fetching...')
    this.isWordCloud = true;
    this.showThematic = true;
    this.isNLP = true;
    this.isNLPTabDisabled = false;
    var request = {"uid": this.uid, "request_id": request_id, "type": 'nlp'}
    var HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "74f3955f67e2489ca82801f19a6f89ac"
      }),
      responseType: "text",
    };

    this.http
      .post<any>(this.endpointRecentQueries, request, HTTPOptions)
      .subscribe((data) => { 
        var requestJSON = JSON.parse(data); 
        this.thematicData = of(JSON.stringify(data));
      })

  }

  isValidJN(jobNo) {
    // check if job number is only digits
    let isnum = /^\d+$/.test(jobNo);
    if (jobNo.length == 9 && isnum) {
      this.isJN = true;
    } else if (jobNo.length == 13 && jobNo.includes("-")) {
      this.isJN = true;
    } else {
      this.simpleAlert();
      this.isJN = false;
    }
  }

  submitQuery() {
    // refresh gapi access token
    this.refreshGAPItoken()
    // check validity of job number
    this.isValidJN(this.jobNo);
    // make BQ call to find previous request IDs
    if (this.isJN) {
      // finalise the request JSON targets
      requestTemplate["chosen_targets"] = this.savedTargets;
      requestTemplate["email"] = this.userEmail;
      requestTemplate["uid"] = this.uid;
      requestTemplate["job_number"] = this.jobNo;
      // finalise the request JSON inclusions
      for (var key in this.savedFilters) {
        if (this.savedFilters[key].length !== 0) {
          requestTemplate["chosen_filters"]["include"][
            this.magdaMap[key + ".json"]
          ] = { items: this.savedFilters[key], aggregated: "True" };
        }
      }
      // finalise the request JSON exlusions
      for (var key in this.savedExclusions) {
        if (this.savedExclusions[key].length !== 0) {
          requestTemplate["chosen_filters"]["exclude"][
            this.magdaMap[key + ".json"]
          ] = { items: this.savedExclusions[key] };
        }
      }
      console.log("Formatted", requestTemplate);
      this.isLoading = true;
      if (this.contentAnalysis) {
        this.isContentAnalysisLoading = true;
        this.isNLP = false;
        this.openSnackBar("Content analysis loading", "Dismiss");
      }

      this.postQueryPromise(requestTemplate)
        .then((data) => {
          if (data[0].length > 0) {
            this.showDownload = true;
            this.isTabDisabled = false;
            this.isLoading = false;
            var uri = this.sanitizer.bypassSecurityTrustUrl(
              "data:text/csv;charset=utf-8," + encodeURIComponent(data)
            );
            this.downloadJsonHref = uri;
            this.openSnackBar("Download Complete", "Dismiss");
            this.apiData = of(data);
            // parse & define request ID
            const csvParsed = d3.csvParse(data);
            const request_ids = csvParsed.map(function (d) {
              return d.query_id;
            });
            this.requestID = request_ids[0];
          }
          if (this.contentAnalysis) {
            // reset progress booleans
            this.contentAnalysisProgress.scraping_progress = "In progress...";
            this.contentAnalysisProgress.cloud_progress = "Pending";
            this.contentAnalysisProgress.thematic_progress = "Pending";
            // trigger scrape + word cloud/thematic generation
            this.triggerScrape(this.apiData).then((resp) => {
              var nlpURI = this.sanitizer.bypassSecurityTrustUrl(
                "data:text/csv;charset=utf-8," + encodeURIComponent(resp)
              )
              var request_id = JSON.parse(resp)['request_id'];
              console.log('RESP ****', JSON.parse(resp)['request_id']);
              // this.downloadJsonHrefScrape = nlpURI;
              this.contentAnalysisProgress.scraping_progress = "Completed";
              this.makeThematic(request_id);
              this.makeCloud(request_id);
            });
          }
        })
        .catch((error) => {
          this.openSnackBar("Request Failed", "Dismiss");
          this.isLoading = false;
          this.contentAnalysisProgress.cloud_progress = "Failed";
          console.log("Promise rejected with " + JSON.stringify(error));
        });
    }
  }

  changeDropDown(data, inclusions) {
    const acceptedValues = [data];
    var filteredObject = {};
    // filter on object value (rather than on key)
    for (const e in this.filterFacets) {
      if (this.filterFacets.hasOwnProperty(e)) {
        if (acceptedValues.indexOf(this.filterFacets[e]) != -1) {
          filteredObject[e] = this.filterFacets[e];
        }
      }
    }
    this.lookupVal = Object.keys(filteredObject)[0].split(".json")[0];
    const lookupSelection = this.urnMapping[this.lookupVal];
    if (inclusions) {
      // update inclusions dropdown with newly selected filter group
      this.buildForm(lookupSelection);
    } else {
      // update exclusions dropdown with newly selected filter group
      this.buildFormExclusion(lookupSelection);
    }
  }

  getJN(data) {
    console.log(this.jobNo);
  }

  saveCheckBox(data) {
    if (!data.checked) {
      this.metricInit = this.metricInit.filter(function (item) {
        return item !== data.source.value;
      });
    } else {
      this.metricInit.push(data.source.value);
      // make sure no duplicates exist
      this.metricInit = Array.from(new Set(this.metricInit));
    }
    requestTemplate["metrics"] = this.metricInit;
    console.log("Checked", requestTemplate);
  }

  callPreviousVisuals(index, request_id) {
    this.selectedRequest = request_id;
    this.isWordCloud = true;
    this.showThematic = true;
    this.isNLP = true;
    this.isNLPTabDisabled = false;
    const self = this;
    // load thematic query from BQ
    const data = [];
    const savedRes = this.savedQueries.getSavedThematic(request_id, this.uid);
    savedRes.then(function (d) {
      // loop over each row & push to an array
      d.result.rows.forEach(function (d) {
        data.push({
          x: +d.f[0].v,
          y: +d.f[1].v,
          topic_num: d.f[2].v,
          topic: d.f[3].v,
          comment: d.f[4].v,
          text_reference: d.f[4].v,
          value: d.f[6].v,
          sentiment: d.f[7].v,
          category: d.f[8].v,
          summary_count: d.f[9].v,
          summary_sentiment: d.f[10].v,
          url: d.f[11].v,
          request_id: d.f[12].v,
          uid: d.f[13].v,
        });
      });
      self.thematicData = of(JSON.stringify(data));
      console.log('EXPECTED THEMATIC DATA', JSON.stringify(data));
    });

    // load keyphrase query from BQ
    const keyphraseData = [];
    const savedResCloud = this.savedQueries.getSavedCloud(request_id, this.uid);
    savedResCloud.then(function (d) {
      // loop over each row & push to an array
      d.result.rows.forEach(function (d) {
        keyphraseData.push({
          keywords: d.f[0].v,
          scores: +d.f[1].v,
          counts: d.f[2].v,
          url: d.f[3].v,
          title: d.f[4].v,
          engagementRate: d.f[5].v,
          uid: d.f[6].v,
          request_id: d.f[7].v,
          "concordances ": d.f[8].v,
        });
      });
      self.wordcloudData = of(JSON.stringify(keyphraseData));
    });
  }

  reset() {
    // reset all fields
    this.savedFilters = {};
    this.formFields = [];
    this.formFieldsExclusions = [];
    this.savedTargets = [];
    this.inclusionJSON = "";
    this.exclusionJSON = "";
    this._inclusionPretty = "";
    this._exclusionPretty = "";
    this.targets = [];
    this.isContentAnalysisLoading = false;
    this.savedFiltersInit(this.filterFacets);
    // initialise dropdown with previous filter group
    const lookupSelection = this.urnMapping[this.lookupVal];
    // reinitialise target selections, filter selections + filter groups
    this.serializedStartDate = new FormControl(this.minDate.toISOString());
    this.serializedEndDate = new FormControl(this.maxDate.toISOString());
    this.matTarget.options.forEach((data: MatOption) => data.deselect());
    this.matInclusionFilter.options.forEach((data: MatOption) =>
      data.deselect()
    );
    this.matExclusionFilter.options.forEach((data: MatOption) =>
      data.deselect()
    );
    this.matInclusionGroup.options.forEach((data: MatOption) =>
      data.deselect()
    );
    this.matExclusionGroup.options.forEach((data: MatOption) =>
      data.deselect()
    );
    this.parseURN(this.urnMapping[this.targetFacet], this.targets, true);
    this.buildForm(lookupSelection);
    this.buildFormExclusion(lookupSelection);
    // reset request template
    requestTemplate["chosen_filters"]["include"] = {};
    requestTemplate["chosen_filters"]["exclude"] = {};
    requestTemplate["chosen_targets"] = [];
    console.log("Reset template: ", requestTemplate);
  }

  restructureMapping(filter_map_gcs) {
    var keys = Object.keys(filter_map_gcs);
    var lookup_strings = keys.map((d) => filter_map_gcs[d]["lookup"]);
    var newMap = (<any>Object).assign(
      ...lookup_strings.map((k, i) => ({ [k]: keys[i] }))
    );
    return newMap;
  }

  saveValues(data, inclusions) {
    var key = this.lookupVal;
    // slight data structure fix with the taxonomy search results
    if (typeof data.value[0] == 'string') {
      this.isTaxonomySearch = false;
      var filters_to_save = data.value;
    } else {
      this.isTaxonomySearch = true;
      var filters_to_save = [].concat.apply([], data.value);
      // fixes the running total of filters across taxonomy searches
      if (this.savedFilters[key] && inclusions) {
        this.savedFilters[key].push(filters_to_save)
        this.savedFilters[key] = Array.from(new Set([].concat.apply([], this.savedFilters[key])));
        filters_to_save = this.savedFilters[key];
      } else if (this.savedExclusions[key] && !inclusions) {
        this.savedExclusions[key].push(filters_to_save);
        filters_to_save = this.savedExclusions[key];
      } else {
        console.log("condition met?")
      }
    }

    if (inclusions) {
      this.savedFilters[key] = filters_to_save;
      this.inclusionJSON = this.prettyifyObject(this.savedFilters, true);
      this._inclusionPretty += this.removeLast(this.inclusionJSON);
    } else {
      this.savedExclusions[key] = filters_to_save;
      this.exclusionJSON = this.prettyifyObject(this.savedExclusions, false);
      this._exclusionPretty += this.removeLast(this.exclusionJSON);
    }
    console.log('saved filters', this.savedFilters);
    // save targets
    this._targetsPretty = this.removeLast(this.prettifyArr(this.savedTargets));
  }

  saveDates(data, type) {
    if (type === "start") {
      this.start_date =
        formatDate(data, this.format, this.locale) + " 00:00:00";
      console.log("Start date: ", this.start_date);
    } else {
      this.end_date = formatDate(data, this.format, this.locale) + " 00:00:00";
      console.log("End date: ", this.end_date);
    }

    var dateObj = {
      use: "on",
      start_date: this.start_date,
      end_date: this.end_date,
      whole_range: "on",
      number_of_days_per_request: 1,
    };
    // switched off temporarily
    requestTemplate["dates"] = dateObj;
  }

  saveTargets(data) {
    var targets_to_save = data.value;
    this.savedTargets = targets_to_save;
    // if URL in saved targets then activate content analysis
    if (this.savedTargets.indexOf("url") > -1) {
      this.contentAnalysis = true;
      console.log("Content analysis on!");
    }
    this._targetsPretty = this._targetsPretty = this.removeLast(
      this.prettifyArr(this.savedTargets)
    );
  }

  buildForm(lookup) {
    this.formFields = [];
    for (const key in lookup) {
      // For each key
      this.formFields.push(key);
    }
    // sort alphabetically
    this.formFields.sort();
    // cap to 1000 entries to reduce loading times
    this.formFields = this.formFields.slice(0, 999);
  }

  buildFormExclusion(lookup) {
    this.formFieldsExclusions = [];
    for (const key in lookup) {
      // For each key
      this.formFieldsExclusions.push(key);
    }
    // sort alphabetically
    this.formFieldsExclusions.sort();
    // cap to 1000 entries to reduce loading times
    this.formFieldsExclusions = this.formFieldsExclusions.slice(0, 999);
  }

  async populateFilters() {
    this.getURNMapping().then((d) => {
      this.urnMapping = JSON.parse(d);
      console.log(this.urnMapping)
    }).then((d) => {
      var filterMappingGCS = this.urnMapping[this.filterName];
      this.filterFacets = this.restructureMapping(filterMappingGCS);
       // set targets
      this.parseURN(this.urnMapping[this.targetFacet], this.targets, true);
      // set filter group names
      this.parseURN(this.filterFacets, this.filterGroups, false);
      // create structure for saved filter object
      this.savedFiltersInit(this.filterFacets);
    })
  }

  async getURNJSONs(gcsURLs) {
    var urnMapping = {};
    // return mapping after all promises complete
    var data = await Promise.all(
      gcsURLs.map((url) =>
        fetch(url)
          .then((res) => res.json())
          .then((out) => {
            var fn = url.split("%2F").slice(-1)[0].split(".json")[0];
            urnMapping[fn] = out;
          })
      )
    ).then((data) => {
      return urnMapping;
    });
    return data;
  }

  async getURNLocation() {
    // Get a reference to the storage service, which is used to create references in your storage bucket
    var storage = this.firebase.app().storage("gs://thenewloom2/");
    var storageRef = storage.ref();
    var listRef = storageRef.child("urn_files");
    var fn;
    var urls = [];
    const res = await storageRef.child("urn_files").list();
    return Promise.all(res.items.map((i) => i.getDownloadURL()));
  }

  async getURNMapping() {
    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "74f3955f67e2489ca82801f19a6f89ac"
      }),
      responseType: "text",
    };

   return this.http.post<any>(this.endpointpopulateFilters, {}, HTTPOptions).toPromise();
  }

  async searchTaxonomy(search) {
    var data = {"search": search, "key": this.lookupVal};

    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "af08a960c8c04143b7ab02d7478a01c6"
      }),
      responseType: "text",
    };

    this.http.post<any>(this.endpointTaxonomy, data, HTTPOptions)
      .subscribe((data) => { 
        var sliced = JSON.parse(data).slice(0, 100);
        this.matchedURNs = sliced.map((i) => Object.keys(i));
        this.formFields = sliced.map((i) => Object.keys(i));       
      })
  }

  parseURN(urnJSON, arr, targets) {
    if (!targets) {
      for (var key of Object.keys(urnJSON)) {
        // if array then loop over each urn value in array
        if (typeof urnJSON[key] === "object") {
          for (var i = 0; i < urnJSON[key].length; i++) {
            var urn = urnJSON[key][i];
            arr.push(urn);
          }
        } else {
          arr.push(urnJSON[key]);
        }
      }
    } else {
      for (var key of Object.keys(urnJSON)) {
        arr.push(key);
      }
    }
    arr = arr.sort();
  }

  prettifyArr(arr) {
    let prettyStr = "";
    for (var target in arr) {
      prettyStr += this.capitalize(arr[target].split("_").join(" ")) + ", ";
    }
    return prettyStr;
  }

  prettyifyObject(obj, inclusion) {
    let prettyStr = "";
    if (inclusion) {
      this._inclusionPretty = "";
    } else {
      this._exclusionPretty = "";
    }
    for (var key in obj) {
      // if array empty
      if (obj[key].length !== 0) {
        console.log(key);
        prettyStr +=
          this.capitalize(this.magdaMap[key + ".json"].split("_").join(" ")) +
          ":";
        obj[key].forEach((filter) => {
          prettyStr += ' <span class="filter-entry">' + filter + "</span>,";
        });
        prettyStr = this.removeLast(prettyStr);
        prettyStr += " <br> <br> <br> ";
      }
    }
    return prettyStr;
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  removeLast(myUrl) {
    if (myUrl.endsWith(", ")) {
      myUrl = myUrl.substring(0, myUrl.length - 2);
    }
    if (myUrl.endsWith(",")) {
      myUrl = myUrl.substring(0, myUrl.length - 2);
    }
    return myUrl;
  }

  savedFiltersInit(filterFacets) {
    // loop over each key + create a holding list for each facet
    for (var key of Object.keys(filterFacets)) {
      this.savedFilters[key] = [];
      this.savedExclusions[key] = [];
    }
  }

  triggerRefresh() {
    var root = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=783j8muundk7cl&scope=rw_audience_engagement&redirect_uri=https://refresh-token-dot-thenewloom.nw.r.appspot.com/&state=';
    var randomStr = (Math.random() + 1).toString(36).substring(7);
    var refreshURL = root + randomStr;
    console.log('*** Refresh URL ***', refreshURL);
    window.open(refreshURL,'_blank')
  }

  refreshGAPItoken() {
    var gapi = window['gapiGlob'];
    console.log(gapi);
    fetch('https://europe-west2-thenewloom.cloudfunctions.net/generate_access_token', {
      method: "GET",
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
    })
    .then(response => response.json())
    .then(data => {
      let refresh_tok = data.access_token;
      gapi.client.setToken({
        access_token: refresh_tok
      })
    });
  }

  async streamToBuffer(readableStream) {
    return new Promise((resolve, reject) => {
      const chunks = [];
      readableStream.on("data", (data) => {
        chunks.push(data instanceof Buffer ? data : Buffer.from(data));
      });
      readableStream.on("end", () => {
        resolve(Buffer.concat(chunks));
      });
      readableStream.on("error", reject);
    });
  }

}
