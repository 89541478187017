import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { Observable } from 'rxjs';
import { DataModel } from '../../data/data.model';
import sample from '../../../assets/sample.json';

@Component({
  selector: 'app-bar-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @ViewChild('chart', {static: false})
  private chartContainer: ElementRef;

  @Input()
  data: DataModel[];
  targets: string[] = [];
  margin = {top: 20, right: 20, bottom: 30, left: 50};

  // exec summary variables
  matches: number; 
  principalMetric: string = 'engagementRate';
  principalTarget: string;
  valueCols: string[] = []
  averageEngagement: any; 
  topResults: string[] = [];
  bottomResults: string[] = [];
  selectedTarget: string; 
  isURL: boolean = false;
  widthAdjust: number = 450;
  marginAdjust: number = 150;
  svgHeight: number = 900;
  svgHeightURL: number = 900;

  constructor() { }

  ngOnInit() {
  }

 ngOnChanges(): void {
    if (!this.data) { return; }
    this.createChart();
  }

  private createChart(): void {
    d3.select('.tooltip').remove();
    const element = this.chartContainer.nativeElement;
    d3.select(element).selectAll("*").remove();
    const raw = this.data;
    const data = d3.csvParse(raw);

    var offsetHeight = this.chartContainer.nativeElement.offsetHeight;

    var svg = d3.select(element).append('svg')
        .attr("class", 'barChart')
        .attr('width', 500)
        .attr('height', this.svgHeight);

    const colour_scale = d3.scaleQuantile()
        .range(["#ffffe5", "#f7fcb9", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#006837", "#004529"]);

    const categoriesNames = data.map(function(d) { return d.target; });
    const metrics = data.map(function(d) { return d3.keys(d); });
    const filteredColumns = metrics[0].map(function(d) {
      // return if string matches metric column names
      if (/engagement|Actor|impression/.test(d)) {
      return d}});
    // remove nulls
    this.valueCols = filteredColumns.filter(x => x !== undefined);
    // remove unnecessary metric
    this.valueCols = this.valueCols.filter(x => x !== 'uniqueActorPercentage');
    // get list of targets used in query
    this.targets = Array.from(new Set(categoriesNames));
    var initTarget = this.targets[0];
    this.principalTarget = this.targets[0];

    if (initTarget == 'url') {
      this.isURL = true;
    } else {
       this.isURL = false;
    }

    var filtered = data.filter(function (el) {
                return el.target == initTarget;
              });

    // order by engagement rate
    filtered = filtered.sort(function(x, y){
        return d3.descending(x.engagementRate, y.engagementRate);
    })

    this.matches = filtered.length;
    this.selectedTarget = initTarget;
    this.topResults = filtered.map(d => d.output).slice(0, 3);
    this.bottomResults = filtered.map(d => d.output).slice(-2);
    // take top 15 & get average engagement
    filtered = filtered.slice(0, 15);
    this.averageEngagement = (d3.mean(filtered.map(d => parseFloat(d.engagementRate))) * 100).toFixed(2);

	  // Define the div for the tooltip
    let div =  d3.select(element).append("div")
			         .attr("class", "tooltip")       
			         .style("opacity", 0);

    const contentWidth = 600 - this.marginAdjust; //element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = 430 - 50; //element.offsetHeight - this.margin.top - this.margin.bottom;

    const x = d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.1)
      .domain(filtered.map(d => d.output));

    const y = d3
      .scaleLinear()
      .rangeRound([contentHeight, 0])
      .domain([0, d3.max(filtered, d => parseFloat(d.engagementRate))]);

    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x))
	  .selectAll("text")	
	    .attr("class", "xLabels")
	    .style("text-anchor", "end")
	    .style("font-size", "0.65rem")
	    .style("font-weight", "100")
	    .style("font-family", "'Raleway', Arial, sans-serif")
	    .attr("dx", "-0.8em")
	    .attr("dy", "1em")
	    .attr("transform", "rotate(-80)");

    g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y).ticks(10, '%'))
      .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('text-anchor', 'end')
        .text('engagementRate');

    g.selectAll('.bar')
      .data(filtered)
      .enter().append('rect')
      	.style("transform", "translate(20px, 0)")
        .attr('class', 'bar')
        .attr("rx", 2)
        .attr("ry", 2)
        .attr('fill', function(d) { return colour_scale(parseFloat(d.engagementRate)); })
        .attr('x', d => x(d.output))
        .attr('y', d => y(parseFloat(d.engagementRate)))
		    .attr('width',  x.bandwidth())
        .attr('height', d => contentHeight - y(parseFloat(d.engagementRate)))
        .on("mouseover", function(d) {
			
			div.transition()    
				.duration(200)    
				.style("opacity", 1.0);  

			// if target is URLs - make tooltip wider + give click thru option
			if (initTarget == "url") { 
					div.html( "<h3>" + d.output.toString().slice(0, 40) + "...</h3>" + "<span style='font-size: 10px; position: absolute; top:4px; right: 9px'> Click on a bar to see the web page </span>" + parseFloat(d.engagementRate).toFixed(2) + '%')  
					 	.style("position", "fixed")
					 	.style('display', 'inline-block')
					 	.style("max-width", "600px")
					 	.style("padding", "1rem")
					 	.style("background-color", "#4a7c7c")
					 	.style("border-radius", "3px")
					 	.style("color", "white")
					 	.style("overflow", "hidden")
					 	.style("text-overflow", "ellipsis")
					 	.style("pointer-events", "none")
					 	.style("white-space", "nowrap")
						.style("left", (d3.event.layerX - this.widthAdjust) + "px")   
						.style("top", (d3.event.layerY - 28) + "px");  
			} else {
				   div.html( "<h3>" + d.output + "</h3>" +  "Engagement Rate" + ": " + parseFloat(d.engagementRate).toFixed(2) + '%')  
					 	.style("position", "fixed")
					 	.style("padding", "1rem")
					 	.style("background-color", "#4a7c7c")
					 	.style("border-radius", "3px")
					 	.style("pointer-events", "none")
					 	.style("font-weight", "200")
					 	.style("color", "white")
            .style("left", (d3.event.layerX - (this.widthAdjust * 3)) + "px")   
						.style("top", (d3.event.layerY - 28) + "px");  
			}

		  d3.select(this).style("fill", "#ff8c41");

    }) 
     .on('mouseout', function (d, i) {
        d3.select(this).transition()
               .duration('50')
               .style('fill', '#ffa07a') 
        // fade out after 2 seconds of inactivity
        setTimeout(function(){  div.transition().duration('1000').style("opacity", 0)}, 3000);
        	}) 

      // reduce size of URLs - too long + also add click through function
      if (initTarget == "url")  {
            g.selectAll('.xLabels')
               .style("font-size", "0.70rem")
               .attr("transform", "rotate(-80)")
               // remove 'https' part of url 
               .text(function(d) { return d.toString().slice(0, 20) + '...' })
               .selectAll("text")

            g.selectAll('rect')
               .on("click", function(d) { 
            window.open(d.output);
            });
              }

   }

  updateChart(target): void {
    d3.select('svg').remove();
    d3.select('.tooltip').remove();

    const element = this.chartContainer.nativeElement;
    d3.select(element).selectAll("*").remove();
    const raw = this.data;
    const data = d3.csvParse(raw);

    let offsetHeight = 0;
	  const refreshInterval = setInterval(() => {
	  if (offsetHeight === 0) {
	    offsetHeight = this.chartContainer.nativeElement.offsetHeight;
	  } else {
	    clearInterval(refreshInterval);
	   }
	  }, 10);

    const svg = d3.select(element).append('svg')
	        .attr('width', 500)
	        .attr('height', this.svgHeight);

    const colour_scale = d3.scaleQuantile()
         .range(["#ffffe5", "#f7fcb9", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#006837", "#004529"]);

    const categoriesNames = data.map(function(d) { return d.target; });
    this.targets = Array.from(new Set(categoriesNames));
    var initTarget = target.value;
    this.principalTarget = target.value;

    var filtered = data.filter(function (el) {
                return el.target == target.value;
              });

    if (initTarget== 'url') {
      this.isURL = true;
    } else {
       this.isURL = false;
    }

    // order by engagement rate
    filtered = filtered.sort(function(x, y){
        return d3.descending(x.engagementRate, y.engagementRate);
    })

    this.matches = filtered.length;
    this.selectedTarget = target.value;
    this.topResults = filtered.map(d => d.output).slice(0, 3);
    this.bottomResults = filtered.map(d => d.output).slice(-2);
    // take top 15 & get average engagement
    filtered = filtered.slice(0, 15);
    this.averageEngagement = (d3.mean(filtered.map(d => parseFloat(d.engagementRate))) * 100).toFixed(2);

	  // Define the div for the tooltip
    let div =  d3.select(element).append("div")
			         .attr("class", "tooltip")       
			         .style("opacity", 0);

    const contentWidth = 600 - this.marginAdjust; //element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = 400 - 50; //element.offsetHeight - this.margin.top - this.margin.bottom;

    const x = d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.1)
      .domain(filtered.map(d => d.output));

    const y = d3
      .scaleLinear()
      .rangeRound([contentHeight, 0])
      .domain([0, d3.max(filtered, d => parseFloat(d.engagementRate))]);

    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x))
	  .selectAll("text")	
	    .attr("class", "xLabels")
	    .style("text-anchor", "end")
	    .style("font-size", "0.65rem")
	    .style("font-weight", "100")
	    .style("font-family", "'Raleway', Arial, sans-serif")
	    .attr("dx", "-0.8em")
	    .attr("dy", "1em")
	    .attr("transform", "rotate(-80)");

    g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(y).ticks(10, '%'))
      .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('text-anchor', 'end')
        .text('engagementRate');

    g.selectAll('.bar')
      .data(filtered)
      .enter().append('rect')
      	.style("transform", "translate(20px, 0)")
        .attr('class', 'bar')
        .attr("rx", 2)
        .attr("ry", 2)
        .attr('fill', function(d) {
                return colour_scale(parseFloat(d.engagementRate));
            })
        .attr('x', d => x(d.output))
        .attr('y', d => y(parseFloat(d.engagementRate)))
		    .attr('width', x.bandwidth())
        .attr('height', d => contentHeight - y(parseFloat(d.engagementRate)))
        .on("mouseover", function(d) {
			
			div.transition()    
				.duration(200)    
				.style("opacity", 1.0);  

		    d3.select(this).style("fill", "#ff8c41");

			// if target is URLs - make tooltip wider + give click thru option
			if (initTarget == "url") { 
					div.html( "<h3>" + removeLast(d.output.toString().slice(0, 40)) + '...' + "</h3>" + "<span style='font-size: 10px; position: absolute; top:4px; right: 9px'> Click on a bar to see the web page </span>" + "Engagement Rate" + ": " + parseFloat(d.engagementRate).toFixed(2) + '%')  
					 	.style("position", "fixed")
					 	.style('display', 'inline-block')
					 	.style("max-width", "600px")
					 	.style("padding", "1rem")
					 	.style("background-color", "#4a7c7c")
					 	.style("border-radius", "3px")
					 	.style("color", "white")
					 	.style("overflow", "hidden")
					 	.style("text-overflow", "ellipsis")
					 	.style("pointer-events", "none")
					 	.style("white-space", "nowrap")
            .style("left", (d3.event.layerX - this.widthAdjust) + "px")    
						.style("top", (d3.event.layerY - 28) + "px");  
			} else {
				   div.html( "<h3>" + d.output + "</h3>" +  "Engagement Rate" + ": " + parseFloat(d.engagementRate).toFixed(2) + '%')  
					 	.style("position", "fixed")
					 	.style("padding", "1rem")
					 	.style("background-color", "#4a7c7c")
					 	.style("border-radius", "3px")
					 	.style("pointer-events", "none")
					 	.style("font-weight", "200")
					 	.style("color", "white")
            .style("left", (d3.event.layerX  - this.widthAdjust) + "px")   
						.style("top", (d3.event.layerY - 28) + "px");  
			}

    }) 
     .on('mouseout', function (d, i) {
        d3.select(this).transition()
               .duration('50')
               .style('fill', '#ffa07a') 

        // fade out after 2 seconds of inactivity
        setTimeout(function(){  div.transition().duration('1000').style("opacity", 0)}, 2000);

        	}
               );

    // reduce size of URLs - too long + also add click through function
      if (initTarget == "url")  {
            g.selectAll('.xLabels')
               .style("font-size", "0.70rem")
               .attr("transform", "rotate(-80)")
               // remove 'https' part of url 
               .text(function(d) { return d.toString().slice(0, 20) + '...' })
               .selectAll("text")

            g.selectAll('rect')
               .on("click", function(d) { 
            window.open(d.output);
            });
              }

  function removeLast(myUrl) {
   		 if (myUrl.endsWith('/')) {
   		 	 myUrl = myUrl.substring(0, myUrl.length - 2);
   		 }
   		 if (myUrl.endsWith('?')) {
   		 	 myUrl = myUrl.substring(0, myUrl.length - 2);
   		 }
    	return myUrl;
		}
}

updateChartMetrics(target): void {
    if (target.value == 'engagementRate') {
      // engagementRate needs to be turned into a percentage
      var multiplier = 100;
    } else {
      // else keep as is
      var multiplier = 1;
    }

    d3.select('svg').remove();
    d3.select('.tooltip').remove();

    const element = this.chartContainer.nativeElement;
    d3.select(element).selectAll("*").remove();
    const raw = this.data;
    const data = d3.csvParse(raw);
    const metric = target.value;
    let offsetHeight = 0;

    const refreshInterval = setInterval(() => {
    if (offsetHeight === 0) {
      offsetHeight = this.chartContainer.nativeElement.offsetHeight;
    } else {
      clearInterval(refreshInterval);
     }
    }, 10);

    const svg = d3.select(element).append('svg')
          .attr('width', 500)
          .attr('height', this.svgHeight);

    const colour_scale = d3.scaleQuantile()
         .range(["#ffffe5", "#f7fcb9", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#006837", "#004529"]);

    const categoriesNames = data.map(function(d) { return d.target; });
    this.targets = Array.from(new Set(categoriesNames));
    this.principalMetric = target.value;
    var initTarget = this.selectedTarget;
    var initTargetOld = this.targets[0];

    var filtered = data.filter(function (el) {
                return el.target == initTarget
              });

    if (initTarget == 'url') {
      this.isURL = true;
    } else {
       this.isURL = false;
    }

    // order by engagement rate
    filtered = filtered.sort(function(x, y){
        return d3.descending(parseFloat(x[metric]), parseFloat(y[metric]));
    })

    this.matches = filtered.length;
    this.topResults = filtered.map(d => d.output).slice(0, 3);
    this.bottomResults = filtered.map(d => d.output).slice(-2);
    // take top 15 & get average engagement
    filtered = filtered.slice(0, 15);
    this.averageEngagement = (d3.mean(filtered.map(d => parseFloat(d[metric]))) * multiplier).toFixed(2);;

    // Define the div for the tooltip
    let div =  d3.select(element).append("div")
               .attr("class", "tooltip")       
               .style("opacity", 0);

    const contentWidth = 600 - this.marginAdjust; //element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = 400 - 50; //element.offsetHeight - this.margin.top - this.margin.bottom;

    const x = d3
      .scaleBand()
      .rangeRound([0, contentWidth])
      .padding(0.1)
      .domain(filtered.map(d => d.output));

    const y = d3
      .scaleLinear()
      .rangeRound([contentHeight, 0])
      .domain([0, d3.max(filtered, d => parseFloat(d[metric]))]);

    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(d3.axisBottom(x))
    .selectAll("text")  
      .attr("class", "xLabels")
      .style("text-anchor", "end")
      .style("font-size", "0.65rem")
      .style("font-weight", "100")
      .style("font-family", "'Raleway', Arial, sans-serif")
      .attr("dx", "-0.8em")
      .attr("dy", "1em")
      .attr("transform", "rotate(-80)");

    // depending on the metric - multiply axis labels to percentage form
    if (target.value == 'engagementRate') {
        g.append('g')
          .attr('class', 'axis axis--y')
          .call(d3.axisLeft(y).ticks(10, '%'))
          .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 6)
            .attr('dy', '0.71em')
            .attr('text-anchor', 'end')
            .text(metric);
     } else {
        g.append('g')
          .attr('class', 'axis axis--y')
          .call(d3.axisLeft(y).ticks(10, '%').tickFormat(d => d + "%"))
          .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 6)
            .attr('dy', '0.71em')
            .attr('text-anchor', 'end')
            .text(metric);
    }

    g.selectAll('.bar')
      .data(filtered)
      .enter().append('rect')
        .style("transform", "translate(20px, 0)")
        .attr('class', 'bar')
        .attr("rx", 2)
        .attr("ry", 2)
        .attr('fill', function(d) {
                return colour_scale(parseFloat(d[metric]));
            })
        .attr('x', d => x(d.output))
        .attr('y', d => y(parseFloat(d[metric])))
        .attr('width', x.bandwidth())
        .attr('height', d => contentHeight - y(parseFloat(d[metric])))
        .on("mouseover", function(d) {
      
          div.transition()    
            .duration(200)    
            .style("opacity", 1.0);  

          d3.select(this).style("fill", "#ff8c41");

          // if target is URLs - make tooltip wider + give click thru option
          if (initTarget == "url") { 
              div.html( "<h3>" + removeLast(d.output.toString().slice(0, 40)) + '...' + "</h3>" + "<span style='font-size: 10px; position: absolute; top:4px; right: 9px'> Click on a bar to see the web page </span>" + metric + ": " + parseFloat(d[metric]).toFixed(2) + '%')  
                .style("position", "fixed")
                .style('display', 'inline-block')
                .style("max-width", "600px")
                .style("padding", "1rem")
                .style("background-color", "#4a7c7c")
                .style("border-radius", "3px")
                .style("color", "white")
                .style("overflow", "hidden")
                .style("text-overflow", "ellipsis")
                .style("pointer-events", "none")
                .style("white-space", "nowrap")
                .style("left", (d3.event.layerX - this.widthAdjust) + "px")    
                .style("top", (d3.event.layerY - 28) + "px");  
            } else {
               div.html( "<h3>" + d.output + "</h3>" +  metric + ": " + parseFloat(d[metric]).toFixed(2) + '%')  
                .style("position", "fixed")
                .style("padding", "1rem")
                .style("background-color", "#4a7c7c")
                .style("border-radius", "3px")
                .style("pointer-events", "none")
                .style("font-weight", "200")
                .style("color", "white")
                .style("left", (d3.event.layerX  - this.widthAdjust) + "px")   
                .style("top", (d3.event.layerY - 28) + "px");  
          }
    }) 
     .on('mouseout', function (d, i) {
        d3.select(this).transition()
               .duration('50')
               .style('fill', '#ffa07a') 

        // fade out after 2 seconds of inactivity
        setTimeout(function(){  div.transition().duration('1000').style("opacity", 0)}, 2000);

          }
               );

    // reduce size of URLs - too long + also add click through function
      if (initTarget == "url")  {
            g.selectAll('.xLabels')
               .style("font-size", "0.70rem")
               .attr("transform", "rotate(-80)")
               // remove 'https' part of url 
               .text(function(d) { return d.toString().slice(0, 20) + '...' })
               .selectAll("text")

            g.selectAll('rect')
               .on("click", function(d) { 
            window.open(d.output);
            });
              }

  function removeLast(myUrl) {
       if (myUrl.endsWith('/')) {
         myUrl = myUrl.substring(0, myUrl.length - 2);
       }
       if (myUrl.endsWith('?')) {
         myUrl = myUrl.substring(0, myUrl.length - 2);
       }
      return myUrl;
    }

}


}