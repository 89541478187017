import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  isIframe = false;
  loginDisplay = false;

  constructor(private authService: MsalService, private router: Router) { }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe({
      next: (result) => { console.log(result) },
                          // this.router.navigate(['/query'])},
      error: (error) => console.log(error)
    }); }

  login() {
      this.authService.loginPopup()
        .subscribe({
          next: (result) => {
            console.log(result);
            this.router.navigate(['/query']);
            this.setLoginDisplay();
          },
          error: (error) => console.log(error)
        });
    }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
}

