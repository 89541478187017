import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SavedQueriesService {
   
  gapi: any;
  thematic_table = "[content_analysis_data.thematic]";
  wordcloud_table = "[content_analysis_data.context_cloud]";
  li_table =  "[content_analysis_data.linkedin]";
  PROJECT_ID = 'thenewloom';

  constructor(private firestore: AngularFirestore) {
   }

  async getSavedQueries(userID) { 
    	this.gapi = window['gapiGlob'];
	  	userID = 'user_' + userID;
	  	var query = 'SELECT request_id FROM ' + this.thematic_table + ' WHERE uid = "' + userID + '" GROUP BY request_id';
	    
	    return this.gapi.client.bigquery.jobs.query({
	            'projectId': this.PROJECT_ID,
	            'timeoutMs': '40000',
	            'query': query
	          })
  }

async getTopicTheme(request_id, userID) {
      this.gapi = window['gapiGlob'];
      userID = 'user_' + userID;
      var query = 'SELECT topic, url FROM ' + this.thematic_table + ' WHERE user_id = "' + userID + '" AND request_id = "' + request_id + '" ORDER BY cast(summary_count as float64) DESC GROUP BY topic LIMIT 3'; // + '" AND query_id = "' + request_id + '" WHERE target = "url"';
      return this.gapi.client.bigquery.jobs.query({
              'projectId': this.PROJECT_ID,
              'timeoutMs': '40000',
              'query': query
            })
}

 async getTimeStamps(userID) {
  	this.gapi = window['gapiGlob'];
    userID = 'user_' + userID;
    var query = 'SELECT user_id, date_created, COUNT(output) as records, query_id FROM ' + this.li_table + ' WHERE user_id = "' + userID + '" AND target = "url" GROUP BY date_created, user_id, query_id'; // + '" AND query_id = "' + request_id + '" WHERE target = "url"';
    return this.gapi.client.bigquery.jobs.query({
  	            'projectId': this.PROJECT_ID,
  	            'timeoutMs': '40000',
  	            'query': query
  	          })
 }

 getSavedThematic(request_id, userID) { 
  	this.gapi = window['gapiGlob'];
  	userID = 'user_' + userID;
    var query = 'SELECT * FROM ' + this.thematic_table + ' WHERE uid = "' + userID + '" AND request_id = "' + request_id + '"';
      
      return this.gapi.client.bigquery.jobs.query({
              'projectId': this.PROJECT_ID,
              'timeoutMs': '40000',
              'query': query
            })
}

 getSavedCloud(request_id, userID) { 
	this.gapi = window['gapiGlob'];
	userID = 'user_' + userID;
  var query = 'SELECT * FROM ' + this.wordcloud_table + ' WHERE uid = "' + userID + '" AND request_id = "' + request_id + '"';
    
    return this.gapi.client.bigquery.jobs.query({
            'projectId': this.PROJECT_ID,
            'timeoutMs': '40000',
            'query': query
          })
}

}
